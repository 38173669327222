import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";
Vue.use(VueRouter);

// const requireAuth = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken == null || accesstoken == undefined) {
//     alert("로그인 후 사용가능 합니다.");
//     next("/");
//   } else {
//     next();
//   }
//   next();
// };

// NavigationDuplicated Error 해결 코드
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

// const requireAdmin = () => (to, from, next) => {
//     let accesstoken = localStorage.getItem("accesstoken");

//     console.log(accesstoken);
//     if (accesstoken) {
//         next();
//     } else {
//         next("/");
//     }
// };

// const requireAdmin = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken && store.state.isAdmin == true) {
//     next();
//   } else {
//     next("/");
//   }
// };
// const redirect = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken) {
//     alert("이미 로그인 하였습니다.");
//     next("/");
//   } else {
//     next();
//   }
// };

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/account/Login.vue"),
  },

  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/Container.vue"),

    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/account/Login.vue"),
      },
    ],
  },
  {
    path: "/error404",
    name: "error404",
    component: () => import("../views/pop/Error404.vue"),
  },
  {
    path: "/error503",
    name: "error503",
    component: () => import("../views/pop/Error503.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Container.vue"),

    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/admin/dashboard/Dashboard.vue"),
      },
      // {
      //   path: "dashboard2",
      //   name: "dashboard2",
      //   component: () => import("../views/admin/Dashboard2.vue"),
      // },
      // {
      //   path: "dashboard3",
      //   name: "dashboard3",
      //   component: () => import("../views/admin/Dashboard3.vue"),
      // },
      // {
      //   path: "dashboard4",
      //   name: "dashboard4",
      //   component: () => import("../views/admin/Dashboard4.vue"),
      // },
      {
        path: "datamanage",
        name: "dataManage",
        component: () => import("../views/admin/data/DataManage.vue"),
      },
      {
        path: "datamanage/register",
        name: "dataRegister",
        component: () => import("../views/admin/data/DataRegister.vue"),
      },
      {
        path: "datamanage/update-multi",
        name: "dataUpdateMulti",
        component: () => import("../views/admin/data/DataUpdateMulti.vue"),
      },
      {
        path: "teammanage",
        name: "teamManage",
        component: () => import("../views/admin/account/TeamManage.vue"),
        // beforeEnter: requireAdmin(),
      },
      {
        path: "usermanage",
        name: "userManage",
        component: () => import("../views/admin/account/UserManage.vue"),
        // beforeEnter: requireAdmin(),
      },
      {
        path: "mypage",
        name: "myPage",
        component: () => import("../views/admin/MyPage.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { x: 0, y: 0 };
      // return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
